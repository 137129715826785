<script>
export default {
  name: 'LogoHyattZiva',
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :class="{ 'fill-current': fill }"
    viewBox="0 0 565 198"
    style="enable-background: new 0 0 1366 768"
    xml:space="preserve"
  >
    <path
      id="Layer"
      fill-rule="evenodd"
      :fill="fill ? '#645e5e' : 'currentColor'"
      d="m319.9 61.9c-11.1 0-18-5.5-19.2-6.6-0.7-0.8-1-1-0.3-2.2l3.1-5.3 1-0.8 1.4 0.3 0.2 0.2c5.1 3.4 9.5 5.2 13.8 5.2 6.5 0 9-3.1 9-7 0-4.3-4.3-7.1-11.9-10.8-10-4.8-15.2-9.8-15.2-18.8 0-9.7 8-15.4 18.5-15.4 9.4 0 16.2 4.7 17.5 5.7l0.6 1-0.2 1.3-3.2 4.7c-0.4 0.6-1.1 1.1-1.8 1.1q-0.3 0.1-0.7-0.2h-0.1c-2.8-1.8-7.7-4.6-12.1-4.6-5.5 0-8 3-8 6.4 0 3.7 2.6 6.5 11.2 10.5 10.7 4.9 16.2 9.5 16.2 19.1 0 9.4-7.4 16.2-19.7 16.2zm223.8-0.9h-8l-1.2-1.3v-23l-19.7-33.2-0.1-1.4 1.2-0.6h8.7l1.1 0.6 14.1 24.1 14-24 1.1-0.7h8.9l1.1 0.6-0.1 1.4-19.8 33-0.1 0.1v23.1zm-58.7 0h-9l-1.1-0.6-11.4-22.7-0.1-0.2h-10.1v22.2l-1.3 1.3h-7.9l-1.3-1.3v-56.9l1.3-1.3h23.5c10.1 0 18.4 6.7 18.4 18.2 0 7-4.7 13.6-12.1 16.6l12.3 22.8v1.3zm-31.7-50.1v17.8h12.2c6.2 0 9.4-4.1 9.4-9.1 0-4.7-3.2-8.7-9.4-8.7zm-60.3 50.1h-8l-1.3-1.2v-40.2l1-1h8.5l1 1v40.2zm-125.3 0h-36.1l-1.3-1.3v-56.9l1.3-1.3h35l1.3 1.3v7l-1.3 1.3h-25.8v14.8h21.6l1.3 1.3v7.1l-1.3 1.3h-21.6v15.7h26.9l1.3 1.3v7.1zm-103.2 0h-7.9l-1.3-1.3v-56.9l1.3-1.3h20.2c11.9 0 20 7.6 20 18.7 0 11.2-8 18.9-19.9 18.9h-11.1v20.6zm1.3-50.1v19h10.3c6.6 0 10.3-4.4 10.3-9.8 0-5-3.7-9.2-10.3-9.2zm-44.1 50.1h-7.5c-1.1 0-1.6-0.3-2.2-1.5l-4.3-9.5h-26.1l-4.3 9.5c-0.3 0.6-0.8 1.5-2.2 1.5h-7.5l-1.1-0.5v-1.2l26.7-57.9 1.1-0.7h0.9l1.1 0.7 26.5 57.9v1.2zm-27.5-39.5l-8.7 19.6h18.3l-8.8-19.4-0.1-0.2zm-66.4 39.5h-8l-1.3-1.3v-40.1l1.1-1.1h8.5l1 1.1v40.1zm383.3-49.9h-44.2l-1.3-1.2v-7.1l1.3-1.3h44.2l1.3 1.3v7.1zm-365.2 0h-44.2l-1.3-1.3v-7.1l1.3-1.2h44.2l1.3 1.2v7.1z"
    />
    <path
      id="Layer"
      fill-rule="evenodd"
      :fill="fill ? '#645e5e' : 'currentColor'"
      d="m494.8 131h-1.4l-24.4-26.8h-0.1v25.1l-1.1 1.1h-5.3l-1.1-1.1v-39.4l1.1-1h1.4l24.4 25.8v-24.1l1.2-1.1h5.3l1.2 1.1v39.4zm-70.2 0c-11.7 0-21-9.3-21-21 0-11.8 9.3-21.2 21-21.2 11.7 0 21.1 9.4 21.1 21.2 0 11.7-9.4 21-21.1 21zm0-34.6c-7.4 0-13.4 6.2-13.4 13.6 0 7.3 6 13.4 13.4 13.4 7.4 0 13.5-6.1 13.5-13.4 0-7.4-6.1-13.6-13.5-13.6zm-111.8 34.6c-12.5 0-21.1-9.2-21.1-21 0-11.9 8.6-21.2 21.1-21.2 5.9 0 10.5 2.1 14 5.3l0.1 1.7-3.3 3.9h-1.4c-2.5-2.2-5.9-3.5-9.2-3.5-8 0-13.1 6.2-13.1 13.6 0 7.3 5.2 13.5 13.1 13.5 3.9 0 7.1-1.3 9.7-3.2h1.4l3 4.2-0.2 1.7c-4.3 3.8-8.9 5-14.1 5zm-175.2 0c-11.7 0-21-9.3-21-21 0-11.8 9.3-21.2 21-21.2 11.7 0 21.1 9.4 21.1 21.2 0 11.7-9.4 21-21.1 21zm0-34.6c-7.4 0-13.4 6.2-13.4 13.6 0 7.3 6 13.4 13.4 13.4 7.4 0 13.5-6.1 13.5-13.4 0-7.4-6.1-13.6-13.5-13.6zm-46.9 34.6c-12.5 0-21.1-9.2-21.1-21 0-11.9 8.6-21.2 21.1-21.2 5.9 0 10.5 2.1 14 5.3l0.1 1.7-3.3 3.9h-1.4c-2.5-2.2-5.9-3.5-9.2-3.5-8 0-13.1 6.2-13.1 13.6 0 7.3 5.2 13.5 13.2 13.5 3.8 0 7-1.3 9.6-3.2h1.4l3 4.2-0.2 1.7c-4.3 3.8-8.9 5-14.1 5zm296.1-0.6h-5.4l-1.1-1.1v-38.8l1.1-1.1h5.4l1.1 1.1v38.8zm-32.3 0h-5.4l-1.1-1.1v-32.9h-11.2l-1.1-1.1v-4.8l1.1-1.1h30l1.1 1.1v4.8l-1.1 1.1h-11.2v32.9zm-77.7 0h-24.4l-1.1-1.2v-38.7l1.1-1.1h24.4l1.1 1.1v4.8l-1.1 1.1h-17.9v9.6h15l1.1 1.1v4.9l-1.1 1.1h-15v10.2h17.9l1.1 1.1v4.8zm-38.7 0h-23.7l-1.1-1.1v-38.7l1.1-1.2h5.4l1.1 1.2v32.8h17.2l1.1 1.1v4.8zm-38.6 0h-23.6l-1.1-1.1v-38.8l1.1-1.1h5.4l1.1 1.1v32.9h17.1l1.1 1.1v4.8z"
    />
    <path
      id="Layer"
      fill-rule="evenodd"
      :fill="fill ? '#645e5e' : 'currentColor'"
      d="m210.9 197.9c-1.7 0-2.4-0.2-3.8-0.5v-4.9h1.3l0.9 2 1.8 0.6c2.7 0 4.4-4 5.5-6.6l0.3-0.6-9.1-20.9-1.8-0.4v-1.5h10v1.6l-2 0.4 5.8 13.6 5.5-13.7-2-0.4v-1.5h6.5v1.5l-1.8 0.4-9 21.7c-2 5-3.9 9.2-8.1 9.2zm119.5-9.9c-7 0-11.6-3-11.6-11.6 0-7.2 4.7-11.9 12-11.9 7.2 0 11.6 4.3 11.6 11.9 0 7-4.7 11.6-12 11.6zm0.2-21.8c-3.3 0-5.4 2.9-5.4 10.1 0 7.1 2.1 10 5.4 10 3.5 0 5.4-2.8 5.4-10 0-7.1-2.1-10.1-5.4-10.1zm-135.5 21.6c-2.7 0-4.4-0.8-5.7-1.6v1.2h-7.7v-1.5l1.9-0.3v-28.8l-1.9-0.3v-1.4h7.7v12.5c2.6-2.1 4.8-3 7.2-3 5.2 0 9 4.6 9 11.2 0 7.6-3.8 12-10.5 12zm-1.7-19.4c-1.2 0-2.5 0.5-4 1.1v14.4c1.3 0.9 2.7 1.9 5 1.9 3.2 0 5.1-3.4 5.1-8.9 0-5.2-2.3-8.5-6.1-8.5zm117.3 19.2c-4.5 0-5.5-2-5.5-5.9v-14.5h-4.1v-2.1h4.2v-4.7l5.7-1.7v6.4h5.2v2.1h-5.2v13.2c0 3.4 0.7 4.5 3.8 4.5 1.1 0 2.1 0 3.1-0.2v1.5c-2.8 1-5.2 1.4-7.2 1.4zm59.5-0.2h-9.8v-1.5l2-0.3v-12.7c0-3.5-1.3-5-4-5-1.2 0-3.2 0.8-4.6 1.8v15.9l2 0.3v1.5h-9.8v-1.5l2-0.3v-18.7l-2-0.3v-1.5h7.8v3c2.4-1.9 4.8-3.5 8-3.5 4.4 0 6.4 2.6 6.4 7.6v13.4l2 0.3c0 0 0 1.5 0 1.5zm-71.1 0h-9.7v-1.5l2-0.3v-28.8l-2-0.3v-1.4h7.8v30.5l2 0.3c0 0 0 1.5-0.1 1.5zm-13.9 0h-9.8v-1.5l2-0.3v-18.8l-2-0.3v-1.4h7.8v20.5l2 0.3c0 0 0 1.5 0 1.5zm-14.3 0h-10.6v-1.7l2.2-0.3v-12.5h-13.5v12.5l2.1 0.3v1.7h-10.4v-1.7l2.1-0.3v-27.5l-2.1-0.3v-1.6h10.4v1.6l-2.1 0.3v11.8h13.5v-11.8l-2.2-0.3v-1.6h10.6v1.6l-2.2 0.3v27.6l2.2 0.2zm114.2-24.7h-2.7v-0.4l0.7-0.2v-4.9l-2.5 5.5h-0.3l-2.3-5.4v4.8l0.6 0.2v0.4h-1.9v-0.4l0.6-0.2v-5.5l-0.6-0.1v-0.5h2.4l1.8 4.4 2-4.4h2.2v0.5l-0.6 0.1v5.5l0.6 0.2zm-13.4 0v-0.5l0.7-0.1v-5.5h-1.7l-0.1 0.7h-0.6v-1.3h6.2v1.3h-0.5l-0.2-0.7h-1.7v5.5l0.8 0.1v0.5zm-91.2-1c-2 0-3.4-1.4-3.4-3.5 0-2 1.4-3.6 3.4-3.6 2 0 3.4 1.6 3.4 3.5 0 2.1-1.4 3.6-3.4 3.6z"
    />
  </svg>
</template>
